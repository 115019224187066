.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: white;
}


.App-header {
  display: flex;
  width: calc(100vw - 48px);
  justify-content: right;
  padding: 24px 12px 0px 24px;
  margin-bottom: 8vh;
}

.Github-link{
  height: 40px;
  margin-left: 12px;
}

.Button-group {
  display: flex;
  margin-bottom: 36px;
}

.Center-div{
  display: flex;
  align-items: center;
  justify-content: center;
}

input {   
  font-size: 18px;;
  border-radius: 4px;
  width: 300px;
  padding: 8px;
  font-style:italic;
  border: rgb(118, 118, 118) 2px solid;
  overflow-x: scroll;
}
.button {
  font-size: 18px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: #8a63d2;
  color: white;
  cursor: pointer;
}
.purple {
  color: #8a63d2;
}
.Input-block{
  max-width: 80%;
}
.Embed-block{
  max-width: 80%;
  border-top: rgb(118, 118, 118) dashed 4px;
  margin-top: 24vh;
  padding-top: 4vh;
  padding-bottom: 30vh;
  min-height:100vh;
}

.Code-box {
  font-size: 18px;
  border-radius: 4px;
  padding: 8px;
  width: 300px;
  font-style:italic;
  color: rgb(118, 118, 118);
  /* border: rgb(118, 118, 118) 2px solid; */
  background-color: white; 
  overflow-x: scroll;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
